import RasaLogo from "@images/rasa-logo.svg";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { createMessage } from "../../../api/secureApi";
import { useState } from "react";
import { loading } from "../../../components/loading";
import { primaryButton } from "../../../constants/classnames";
import Select from "react-tailwindcss-select";
import { contactOptions } from "./NoResultsModal";

export function ContactMeModal({
  open,
  setOpen,
  profileId,
  setReceivedContactRequest,
  setReceivedAnyMessage,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [preferredContactMethod, setPreferredContactMethod] = useState(null);
  const [error, setError] = useState("");

  const handleContactChange = (obj) => {
    setPreferredContactMethod(obj);
  };

  const handleSendMessage = () => {
    if (preferredContactMethod) {
      const dataToSubmit = {
        body: "",
        type: "contact_me",
        contact_media: preferredContactMethod.value,
      };
      setIsLoading(true);
      createMessage(profileId, dataToSubmit)
        .then(() => {
          setError("");
          setReceivedContactRequest(true);
          setReceivedAnyMessage(true);
          setOpen(false);
          setIsLoading(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("error", error);
          setReceivedContactRequest(false);
          setError(
            "There was an error sending your request." +
              " Please email us at tech-help@rasa-legal.com",
          );
          setIsLoading(false);
        });
    } else {
      if (!preferredContactMethod) {
        setError("Please select a preferred method for us to contact you.");
      }
    }
  };

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-300 opacity-50" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[95vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-pink-100 py-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex h-[75vh] flex-col">
            <div className="pb-6 pr-6">
              <XMarkIcon
                className="float-right h-9 w-9 cursor-pointer text-rasapink"
                onClick={() => setOpen(false)}
              />
            </div>
            <div className="flex justify-center">
              <RasaLogo className="h-9" />
            </div>
            {/*<AlertDialog.Title className="mt-8 px-4 font-serif text-lg">*/}
            {/*  How would like us to contact you?*/}
            {/*</AlertDialog.Title>*/}
            <AlertDialog.Description className="mb-8 px-6">
              <section id="form">
                <form>
                  <div className="container mx-auto mb-16 mt-10 flex max-w-3xl flex-col px-4">
                    <div className="flex flex-col justify-center">
                      <div className="my-8 px-4 font-serif text-lg">
                        How would like us to contact you?
                      </div>
                      <div className="relative my-2 rounded-md shadow-sm">
                        <Select
                          id="contactMethods"
                          value={preferredContactMethod}
                          onChange={handleContactChange}
                          options={contactOptions}
                          isMultiple={false}
                          // isClearable
                          classNames={{
                            menuButton: () =>
                              "flex text-sm px-2 py-2 border border-black rounded shadow-sm transition-all duration-300 focus:outline-none" +
                              " bg-white hover:border-gray-400 focus:border-pink-500 focus:ring focus:ring-pink-500/20 [&_p]:whitespace-break-spaces",
                            listItem: () =>
                              "block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded" +
                              " break-normal text-gray-500 hover:bg-pink-100 hover:text-rasapink",
                            tagItem: () =>
                              "flex text-sm bg-purple p-1 mr-1 border border-gray-200 rounded [&_svg]:w-4",
                          }}
                        />
                      </div>
                      <div className="my-6 flex flex-col justify-center">
                        {error && (
                          <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                            {error}
                          </div>
                        )}
                        <button onClick={handleSendMessage} type="button" className={primaryButton}>
                          {isLoading ? loading : "send request for contact"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </section>
            </AlertDialog.Description>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
