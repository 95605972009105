import { useEffect, useState } from "react";
import { toTitleCase } from "../../../utils/toTitleCase";
import { CardAZ } from "./CardAZ";
import {
  ELIGIBLE,
  FELONY,
  INELIGIBLE,
  MISDEMEANOR,
  NOT_APPLICABLE,
  TRAFFIC,
  UNKNOWN,
} from "./results";
import { compare } from "./CardSectionUT";
import { recordLabel } from "../../../constants/classnames";

export const CardSectionAZ = ({
  arizonaCounties,
  data,
  openCases,
  openMessageUs,
  onProbationOrParole,
  receivedAnyMessage,
  setOneIsEligible,
  setOpenMessageUs,
  hasFreshworks,
}) => {
  const [marijuanaCards, setMarijuanaCards] = useState([]);
  const [misdemeanorCards, setMisdemeanorCards] = useState([]);
  const [felonyCards, setFelonyCards] = useState([]);
  const [trafficCards, setTrafficCards] = useState([]);

  useEffect(() => {
    const marijCards = [];
    const missCards = [];
    const felCards = [];
    const trafCards = [];
    // eslint-disable-next-line no-console
    console.log("data before data.map", data);
    // in the record obj, we get the lead charge within that record
    // then get the remedies for that lead charge
    data.map((record) => {
      let expungementEligible = false;
      let sealingEligibleDate = "";
      let sealingEligibleNow = false;
      let sealingEligibleLater = false;
      let setAsideEligibleDate = "";
      let setAsideEligibleNow = false;
      let setAsideEligibleLater = false;
      let secondChanceEligibleNow = false;
      let secondChanceEligibleLater = false;
      let secondChanceEligibleDate = "";
      let rightsRestorationEligibleNow = false;
      let rightsRestorationEligibleLater = false;
      let rightsRestorationEligibleDate = "";
      let needMoreInfo = false;
      let notEligible = false;

      let leadChargeNumber = record.lead_charge;
      let leadCharge = record.charges.find((charge) => charge.count_number === leadChargeNumber);
      const remedyCount = leadCharge.remedies.length;
      const unknownCount = leadCharge.remedies.filter(
        (r) =>
          r.eligibility?.toLowerCase() === UNKNOWN ||
          r.eligibility?.toLowerCase() === NOT_APPLICABLE,
      ).length;
      const notEligibleCount = leadCharge.remedies.filter(
        (r) =>
          r.eligibility?.toLowerCase() === INELIGIBLE ||
          r.eligibility?.toLowerCase() === NOT_APPLICABLE,
      ).length;

      if (remedyCount === unknownCount) {
        needMoreInfo = true;
      } else if (remedyCount === notEligibleCount) {
        notEligible = true;
      } else {
        leadCharge.remedies.forEach((remedy) => {
          const today = new Date();
          if (
            remedy.type.toLowerCase() === "expungement" &&
            remedy.eligibility.toLowerCase() === ELIGIBLE
          ) {
            expungementEligible = true;
          } else if (remedy.type.toLowerCase() === "sealing") {
            const eligibleBy = remedy.eligible_by ? new Date(remedy.eligible_by) : null;
            if (remedy.eligibility.toLowerCase() === ELIGIBLE) {
              sealingEligibleNow = true;
              if (!openCases || !onProbationOrParole) {
                setOneIsEligible(true);
              }
            } else if (eligibleBy > today) {
              sealingEligibleLater = true;
              sealingEligibleDate = eligibleBy.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
            }
          } else if (remedy.type.toLowerCase() === "set aside") {
            const eligibleBy = remedy.eligible_by ? new Date(remedy.eligible_by) : null;
            if (remedy.eligibility.toLowerCase() === ELIGIBLE) {
              setAsideEligibleNow = true;
            } else if (eligibleBy > today) {
              setAsideEligibleLater = true;
              setAsideEligibleDate = eligibleBy.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
            }
          } else if (remedy.type.toLowerCase() === "second chance") {
            const eligibleBy = remedy.eligible_by ? new Date(remedy.eligible_by) : null;
            if (remedy.eligibility.toLowerCase() === ELIGIBLE) {
              secondChanceEligibleNow = true;
            } else if (eligibleBy > today) {
              secondChanceEligibleLater = true;
              secondChanceEligibleDate = eligibleBy.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
            }
          } else if (remedy.type.toLowerCase() === "rights restoration") {
            const eligibleBy = remedy.eligible_by ? new Date(remedy.eligible_by) : null;
            if (remedy.eligibility.toLowerCase() === ELIGIBLE) {
              rightsRestorationEligibleNow = true;
            } else if (eligibleBy > today) {
              rightsRestorationEligibleLater = true;
              rightsRestorationEligibleDate = eligibleBy.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
            }
          }
        });
      }

      const cardData = {
        caseNumber: record.case_number,
        court: record.court,
        violation: leadCharge.description
          ? toTitleCase(leadCharge.description.toLowerCase())
          : "--",
        violationCode: record.code,
        disposition: leadCharge.disposition
          ? toTitleCase(leadCharge.disposition.toLowerCase())
          : "--",
        dispositionDate: leadCharge.disposition_date
          ? toTitleCase(leadCharge.disposition_date.toLowerCase())
          : "--",
        severity: leadCharge.severity ? toTitleCase(leadCharge.severity.toLowerCase()) : "--",
        charges: record.charges,
        expungementEligible: expungementEligible,
        sealingEligibleDate: sealingEligibleDate,
        sealingEligibleNow: sealingEligibleNow,
        sealingEligibleLater: sealingEligibleLater,
        setAsideEligibleDate: setAsideEligibleDate,
        setAsideEligibleNow: setAsideEligibleNow,
        setAsideEligibleLater: setAsideEligibleLater,
        secondChanceEligibleDate: secondChanceEligibleDate,
        secondChanceEligibleNow: secondChanceEligibleNow,
        secondChanceEligibleLater: secondChanceEligibleLater,
        rightsRestorationEligibleDate: rightsRestorationEligibleDate,
        rightsRestorationEligibleNow: rightsRestorationEligibleNow,
        rightsRestorationEligibleLater: rightsRestorationEligibleLater,
        needMoreInfo: needMoreInfo,
        notEligible: notEligible,
      };

      const currentCard = (
        <CardAZ
          key={cardData.caseNumber}
          data={cardData}
          openMessageUs={openMessageUs}
          setOpenMessageUs={setOpenMessageUs}
          openCases={openCases}
          onProbationOrParole={onProbationOrParole}
          receivedAnyMessage={receivedAnyMessage}
          hasFreshworks={hasFreshworks}
        />
      );

      if (expungementEligible) {
        marijCards.push(currentCard);
      } else if (leadCharge.severity_type.toLowerCase().includes(MISDEMEANOR)) {
        missCards.push(currentCard);
      } else if (leadCharge.severity_type.toLowerCase().includes(FELONY)) {
        felCards.push(currentCard);
      } else if (leadCharge.severity_type.toLowerCase().includes(TRAFFIC)) {
        trafCards.push(currentCard);
      }
    });

    const felSorted = felCards.sort(compare);
    const misSorted = missCards.sort(compare);
    const marijSorted = marijCards.sort(compare);
    const trafSorted = trafCards.sort(compare);

    setFelonyCards(felSorted);
    setMisdemeanorCards(misSorted);
    setMarijuanaCards(marijSorted);
    setTrafficCards(trafSorted);
  }, [
    openMessageUs,
    data,
    openCases,
    onProbationOrParole,
    receivedAnyMessage,
    setOneIsEligible,
    setOpenMessageUs,
    hasFreshworks,
  ]);

  return (
    <>
      <section className="bg-pink-100 pb-12 pt-1">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h3 className={recordLabel}>felony records</h3>
          <div>
            We found {felonyCards.length === 0 ? "no" : felonyCards.length} felony{" "}
            {felonyCards.length === 1 ? "record" : "records"}
            {felonyCards.length === 0 ? "." : ":"}
          </div>
          {felonyCards.map((card) => card)}
        </div>
      </section>
      <section className="bg-gray-100 pb-12 pt-1">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h3 className={recordLabel}>misdemeanor records</h3>
          <div>
            We found {misdemeanorCards.length === 0 ? "no" : misdemeanorCards.length} misdemeanor{" "}
            {misdemeanorCards.length === 1 ? "record" : "records"}
            {misdemeanorCards.length === 0 ? "." : ":"}
          </div>
          {misdemeanorCards.map((card) => card)}
        </div>
      </section>
      <section className="bg-green-100 pb-12 pt-1">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h3 className={recordLabel}>marijuana related records</h3>
          <div>
            We found {marijuanaCards.length === 0 ? "no" : marijuanaCards.length} marijuana related{" "}
            {marijuanaCards.length === 1 ? "record" : "records"}
            {marijuanaCards.length === 0 ? "." : ":"}
          </div>
          {marijuanaCards.map((card) => card)}
        </div>
      </section>
      <section className="bg-blue-50 pb-12 pt-1">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h3 className={recordLabel}>traffic records</h3>
          <div>
            We found {trafficCards.length === 0 ? "no" : trafficCards.length} traffic{" "}
            {trafficCards.length === 1 ? "record" : "records"}
            {trafficCards.length === 0 ? "." : ":"}
          </div>
          {trafficCards.map((card) => card)}
        </div>
      </section>
      {arizonaCounties && arizonaCounties.length > 0 && (
        <section className="pb-12 pt-1">
          <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
            <h4 className="mb-4 font-serif text-xl">
              You answered that you may have records in these counties that we don't have complete{" "}
              court data for:
            </h4>
            <ul className="ml-4">
              {arizonaCounties.map((county) => {
                return (
                  <li key={county} className="list-disc">
                    {county}
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
      )}
    </>
  );
};
