import { messageStatus, primaryButtonSmall } from "../../../constants/classnames";
import { Link } from "react-router-dom";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { CONTACT_TIME, PATIENT_MESSAGE } from "./results";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { STATE_LIST, UT } from "../../../constants/usStates";
import { rasaTextNumber } from "./contactUsModal";
import { useEffect, useState } from "react";

export const ContactBox = ({ setOpenContactMe, hasFreshworks }) => {
  return (
    <section className="m-4 mx-auto inline-block rounded-md border border-rasapink px-6 py-4 text-center">
      <h3 className="mb-4 font-serif text-xl">You may have record(s) to clear!</h3>
      <div>
        <button onClick={() => setOpenContactMe(true)} type="button" className={primaryButtonSmall}>
          have us contact you
        </button>
      </div>
      {hasFreshworks && (
        <>
          <div>or</div>
          <div className="mt-4">
            you can text us at{" "}
            <a className="mr-4 text-rasapink underline" href={`sms:${rasaTextNumber}`}>
              {rasaTextNumber}.
            </a>
          </div>
        </>
      )}
    </section>
  );
};

export const ErrorBox = ({ handleErrorMessageUsClick }) => {
  return (
    <div className="my-6">
      Rasa is powered by court data, which is not perfect. If you don't think these cases belong to
      you, notice a case is missing, or you find an error,{" "}
      <Link onClick={() => handleErrorMessageUsClick(true)}>
        <span className="text-rasapink underline">please message us here.</span>
      </Link>
    </div>
  );
};

export const TextUs = () => {
  return (
    <div className="my-2 text-center">
      <div>You can text us!</div>
      Our text number is:{" "}
      <a className="mr-4 text-rasapink underline" href={`sms:${rasaTextNumber}`}>
        {rasaTextNumber}.
      </a>
    </div>
  );
};

export const WelcomeBox = ({
  birthDate,
  handleErrorMessageUsClick,
  hasFreshworks,
  ineligibleReasons,
  numOfRecords,
  criminalCount,
  trafficCount,
  openCases,
  onProbationOrParole,
  person,
  receivedContactRequest,
  receivedMessage,
  receivedAnyMessage,
  receivedNoResultsMessage,
  recordOrRecords,
  showConsultBox,
  setOpenContactMe,
  setOpenMessageUs,
  usState,
}) => {
  const [aliases, setAliases] = useState("");
  const [aliasCount, setAliasCount] = useState(0);
  // eslint-disable-next-line no-console
  console.log("person", person);
  useEffect(() => {
    setAliasCount(person.aliases.length);
    if (person.aliases.length > 0) {
      let str = "";
      person.aliases.map((alias) => {
        str += `${alias.first_name} ${alias.last_name}, `;
      });
      setAliases(str);
    }
  }, [person.aliases]);

  return (
    <section>
      <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
        <h2 className="my-2 font-serif text-2xl">
          Welcome{person.first_name ? ", " + person.first_name : ""}
        </h2>
        <div>
          {receivedContactRequest && (
            <div className={messageStatus}>
              <div>Thank you for reaching out!</div>
              <div>We will {CONTACT_TIME}</div>
              <div>{PATIENT_MESSAGE}</div>
            </div>
          )}
          {receivedMessage && !receivedNoResultsMessage && (
            <div className={messageStatus}>
              <div>Thank you for your message!</div>
              <div>We will {CONTACT_TIME}</div>
              <div>{PATIENT_MESSAGE}</div>
            </div>
          )}
          {receivedNoResultsMessage ? (
            <div className={messageStatus}>
              <div>Thank you for your message!</div>
              <div>We are doing a manual search for your records and will {CONTACT_TIME}</div>
              <div>{PATIENT_MESSAGE}</div>
            </div>
          ) : (
            <div>
              <div className="my-4 text-lg">
                We found{" "}
                {usState === UT ? (
                  <>
                    <span>
                      {criminalCount} criminal record{criminalCount === 1 ? "" : "s"}
                    </span>{" "}
                    and{" "}
                    <span>
                      {trafficCount} traffic record{trafficCount === 1 ? "" : "s"}
                    </span>{" "}
                    in {STATE_LIST[usState]}
                  </>
                ) : (
                  <b>
                    {numOfRecords} {STATE_LIST[usState]} {recordOrRecords}
                  </b>
                )}{" "}
                that match the name{" "}
                <b>
                  {person.first_name} {person.last_name}
                </b>
                {aliases !== "" && (
                  <>
                    , {aliasCount === 1 ? "alias" : "aliases"} <b>{aliases}</b>
                  </>
                )}{" "}
                and birth date
                <b> {birthDate}</b> you provided.
              </div>
            </div>
          )}
          <div className="my-3 text-center font-bold leading-4 text-gray-600">
            Please note that this is a preliminary screening and is not legal advice.
          </div>
        </div>
        {showConsultBox && (
          <ContactBox setOpenContactMe={setOpenContactMe} hasFreshworks={hasFreshworks} />
        )}
        {openCases && (
          <div className="relative my-2 rounded-md bg-amber-100 px-6 py-4">
            <div className="absolute -top-3 flex h-6 w-6 items-center justify-center rounded-full bg-amber-100">
              <ExclamationTriangleIcon className="h-4 text-amber-400" />
            </div>
            <div>
              You answered that you currently have open cases. This probably means you are{" "}
              <b>not eligible</b> to clear your records right now.
            </div>
          </div>
        )}
        {onProbationOrParole && (
          <div className="relative my-2 rounded-md bg-amber-100 px-6 py-4">
            <div className="absolute -top-3 flex h-6 w-6 items-center justify-center rounded-full bg-amber-100">
              <ExclamationTriangleIcon className="h-4 text-amber-400" />
            </div>
            <div>
              You answered that you are currently on probation or parole. This probably means you{" "}
              are <b>not eligible</b> to clear your records right now.
            </div>
          </div>
        )}
        {usState === UT && ineligibleReasons && (
          <>
            <div className="my-4">
              <XCircleIcon className="float-left mr-1 h-6 text-red-600" />
              Not eligible
            </div>
            <div className="my-2">
              <span className="font-bold">Reason(s):</span> {ineligibleReasons}
            </div>
            <div className="my-2">
              In some cases, a person who is not eligible for expungement{" "}
              <b>
                <i>may</i>
              </b>{" "}
              become eligible by seeking and successfully obtaining a{" "}
              <span className="text-rasapink underline">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.rasa-legal.com/utah/utahs-offence-reduction-process/"
                >
                  402 Reduction.
                </a>
              </span>{" "}
              {!receivedAnyMessage && (
                <>
                  To discuss this possibility with with one of our experts, please{" "}
                  <Link onClick={() => setOpenMessageUs(true)}>
                    <span className="text-rasapink underline">click here to message us.</span>
                  </Link>
                </>
              )}
            </div>
            <div className="my-2">
              If you have a case type that is not eligible for expungement or you have too many
              cases to be eligible for expungement, you might be able to obtain a pardon. For
              information about Utah's pardon process,{" "}
              <span className="text-rasapink underline">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.rasa-legal.com/utah/utah-pardon-information/"
                >
                  click here.
                </a>
              </span>
            </div>
          </>
        )}
        {!receivedMessage && !receivedNoResultsMessage && !receivedContactRequest && (
          <ErrorBox handleErrorMessageUsClick={handleErrorMessageUsClick} />
        )}
        {hasFreshworks && <TextUs />}
      </div>
    </section>
  );
};
