export const jobOpportunitiesOptions = [
  {
    value: "Facebook groups (please specify below)",
    label: "Facebook groups (please specify below)",
  },
  { value: "Indeed", label: "Indeed" },
  { value: "job fair", label: "job fair" },
  { value: "LinkedIn", label: "LinkedIn" },
  {
    value: "other online job board (please specify below)",
    label: "other online job board (please specify below)",
  },
  { value: "word of mouth", label: "word of mouth" },
  { value: "Zip Recruiter", label: "Zip Recruiter" },
  { value: "other (please specify below)", label: "other (please specify below)" },
];

export const industryOptions = [
  {
    value: "Business",
    label: "Business",
  },
  { value: "Construction", label: "Construction" },
  { value: "Education", label: "Education" },
  {
    value: "Food and Beverage",
    label: "Food and Beverage",
  },
  { value: "Health", label: "Health" },
  { value: "Hospitality", label: "Hospitality" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Professional Services", label: "Professional Services" },
  { value: "Retail", label: "Retail" },
  {
    value: "Technology",
    label: "Technology",
  },
  { value: "Transportation", label: "Transportation" },
  { value: "Other (please specify below)", label: "Other (please specify below)" },
];

export const educationLevelOptions = [
  { value: "some high school", label: "some high school" },
  { value: "high school diploma", label: "high school diploma" },
  { value: "GED", label: "GED" },
  { value: "some college", label: "some college" },
  { value: "associate's degree", label: "associate's degree" },
  { value: "bachelor's degree", label: "bachelor's degree" },
  { value: "graduate degree", label: "graduate degree" },
];

export const whyRecordClearingOptions = [
  { value: "to get a promotion", label: "to get a promotion" },
  { value: "to get a new job", label: "to get a new job" },
  {
    value: "to improve ability to rent a house or apartment",
    label: "to improve ability to rent a house or apartment",
  },
  {
    value: "to be able to volunteer at my children's school",
    label: "to be able to volunteer at my children's school",
  },
  { value: "to get a college education", label: "to get a college education" },
  { value: "to get a professional license", label: "to get a professional license" },
  { value: "to travel freely", label: "to travel freely" },
  {
    value: "to increase self-confidence",
    label: "to increase self-confidence",
  },
  {
    value: "to eliminate the stigma associated with a record",
    label: "to eliminate the stigma associated with a record",
  },
  {
    value: "to restore my gun rights",
    label: "to restore my gun rights",
  },
  {
    value: "to obtain life insurance",
    label: "to obtain life insurance",
  },
  {
    value: "for religious reasons",
    label: "for religious reasons",
  },
  { value: "other (please specify below)", label: "other (please specify below)" },
];
