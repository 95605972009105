import RasaLogo from "@images/rasa-logo.svg";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { primaryButton, secondaryButton } from "../../../../../constants/classnames";
import { Link } from "react-router-dom";

export function EmailInUseModal({ open, setOpen, email }) {
  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-300 opacity-50" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[95vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-pink-100 py-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex h-[80vh] flex-col">
            <div className="pb-6 pr-6">
              <XMarkIcon
                className="float-right h-9 w-9 cursor-pointer text-rasapink"
                onClick={() => setOpen(false)}
              />
            </div>
            <div className="flex justify-center">
              <RasaLogo className="h-9" />
            </div>
            <AlertDialog.Title className="mt-6 px-10 font-serif text-xl">
              Is {email ? <u>{email}</u> : "this"} your email?
            </AlertDialog.Title>
            <div className="mb-8 px-6 text-lg">
              <div className="container mx-auto mb-16 mt-2 flex max-w-3xl flex-col px-4">
                <div className="pb-4">
                  <label htmlFor="message" className="block leading-6 text-gray-900">
                    If you've used this email with Rasa before, you can login to continue where you
                    left off.
                  </label>
                </div>
                <div className="flex flex-col justify-center">
                  <Link to="/login">
                    <button type="button" className={primaryButton}>
                      go to login page
                    </button>
                  </Link>
                  <button onClick={() => setOpen(false)} type="button" className={secondaryButton}>
                    use a different email
                  </button>
                </div>
              </div>
            </div>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
