import { useState } from "react";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { CaseDetailsModalUT } from "./CaseDetailsModalUT";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { AutoInfoModal } from "./AutoInfoModal";

export const CardUT = ({
  data,
  openMessageUs,
  setOpenMessageUs,
  openCases,
  onProbationOrParole,
  receivedAnyMessage,
  hasFreshworks,
}) => {
  const [open, setOpen] = useState(false);
  const [openAutoInfo, setOpenAutoInfo] = useState(false);

  const options = { year: "numeric", month: "long", day: "numeric" };
  const dispositionDate = new Date(data.dispositionDate).toLocaleDateString("en-US", options);

  return (
    <div className="my-4 mr-4 rounded bg-white p-4">
      <h4 className="font-serif text-2xl tracking-tight">{data.violation}</h4>
      <h4 className="mb-6 font-serif text-lg tracking-tight">{data.caseNumber}</h4>
      <div className="mb-1 text-gray-500">{dispositionDate}</div>
      <div className="mb-1 text-gray-500">
        {data.severity} - {data.disposition}
      </div>
      {data.expungementEligibleNow ? (
        <>
          {data.expungementEligibleNow && !openCases && !onProbationOrParole && (
            <div>
              <CheckCircleIcon className="float-left mr-1 h-6 text-green-500" />
              <span className="tracking-tighter">Expungement Eligible Now</span>
            </div>
          )}
        </>
      ) : (
        <>
          {data.expungementEligibleLater && (
            <div>
              <ExclamationTriangleIcon className="float-left mr-1 h-6 text-orange-400" />
              <span className="tracking-tighter">Expungement Eligible Later</span>
            </div>
          )}
          {data.needMoreInfo && (
            <div>
              <QuestionMarkCircleIcon className="float-left mr-1 h-6 text-rasapink" />
              We need more information to help you with this case
            </div>
          )}
          {!data.expungementEligibleNow && !data.expungementEligibleLater && !data.needMoreInfo && (
            <div>
              <XCircleIcon className="float-left mr-1 h-6 text-red-600" />
              <span className="tracking-tighter">Not eligible for expungement</span>
            </div>
          )}
        </>
      )}
      {data.autoExpungementEligibleNow ? (
        <>
          {!openCases && !onProbationOrParole && (
            <div>
              <ExclamationTriangleIcon
                className="float-left mr-1 h-6 text-orange-400"
                onClick={() => setOpenAutoInfo(true)}
              />
              <span className="tracking-tighter">Auto-Expungement Eligible</span>
            </div>
          )}
        </>
      ) : (
        <>
          {data.autoExpungementEligibleLater && (
            <div>
              <ExclamationTriangleIcon className="float-left mr-1 h-6 text-orange-400" />
              <span className="tracking-tighter">Auto-Expungement Eligible Later</span>
              <InformationCircleIcon
                className="relative -top-1 inline h-6 cursor-pointer text-rasapink"
                onClick={() => setOpenAutoInfo(true)}
              />
            </div>
          )}
        </>
      )}
      <button
        type="button"
        className="my-4 w-full rounded-full border border-rasapink bg-white p-4 font-bold text-black hover:shadow-xl"
        onClick={() => setOpen(true)}
      >
        see record
      </button>
      <AutoInfoModal open={openAutoInfo} setOpen={setOpenAutoInfo} />
      <CaseDetailsModalUT
        open={open}
        setOpen={setOpen}
        openMessageUs={openMessageUs}
        setOpenMessageUs={setOpenMessageUs}
        data={data}
        openCases={openCases}
        onProbationOrParole={onProbationOrParole}
        receivedAnyMessage={receivedAnyMessage}
        autoExpungementEligibleNow={data.autoExpungementEligibleNow}
        hasFreshworks={hasFreshworks}
      />
    </div>
  );
};
