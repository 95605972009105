import { Link, Outlet, useNavigate } from "react-router-dom";
import { logo } from "../constants/classnames";
import RasaLogo from "@images/rasa-logo.svg";

export function SurveyLayout() {
  const navigate = useNavigate();
  const handleLogoutClick = () => {
    localStorage.setItem("token", "");
    localStorage.setItem("profile_id", "");
    navigate("/login");
  };

  return (
    <>
      <div className="flex justify-between">
        <div />
        <div>
          <Link onClick={handleLogoutClick}>
            <div className="mt-4 px-6 text-base tracking-tighter text-rasapink">log out</div>
          </Link>
        </div>
      </div>
      <div className={logo}>
        <RasaLogo className="h-7" />
      </div>
      <Outlet />
    </>
  );
}
