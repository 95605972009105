import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { verifyPerson } from "../../../api/secureApi";
import { ContactUsModal } from "../results/contactUsModal";
import { checkTokenExpired } from "../../../utils/handleError";
import { questionMain3 } from "../../../constants/classnames";

export const Verification = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [verifiedViaCC, setVerifiedViaCC] = useState(false);
  const [hasFreshworks, setHasFreshworks] = useState(false);
  const [verifyFailed, setVerifyFailed] = useState(false);
  const [openContactUs, setOpenContactUs] = useState(false);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  // eslint-disable-next-line no-console
  console.log("state in verification", state);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    if (state) {
      setVerifiedViaCC(!!state.verified);
      setHasFreshworks(!!state.freshworks_id);
    }
  }, [state]);

  const onSubmit = (data) => {
    const newState = { ...data, ...state };
    // eslint-disable-next-line no-console
    console.log(newState);
    if (!verifiedViaCC) {
      // const verifyState = { ssn: data.ssn };
      verifyPerson({ ssn: data.ssn })
        .then((verifyResponse) => {
          // eslint-disable-next-line no-console
          console.log(verifyResponse);
          if (verifyResponse.verified) {
            getEligibilityAndNavigate(verifyResponse);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          if (typeof error?.response?.data?.detail === "string") {
            checkTokenExpired(error, navigate);
            // if (error && error.response && error.response.data && error.response.data.detail) {
            if (error.response.data.detail === "Already verified") {
              getEligibilityAndNavigate(newState);
            } else if (error.response.data.detail === "Verification failed") {
              setGenericErrorMessage("Verification failed");
              setVerifyFailed(true);
              setOpenContactUs(true);
            } else {
              setGenericErrorMessage(error.response.data.detail);
            }
          } else {
            setGenericErrorMessage("An error has occurred.");
          }
        });
    } else {
      getEligibilityAndNavigate(newState);
    }
  };

  const getEligibilityAndNavigate = (dataToSubmit) => {
    navigate("/results", { state: dataToSubmit });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className={questionMain3}>verification</h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto mt-10 flex max-w-3xl flex-col justify-center px-4">
            <div className="mb-6 overflow-hidden rounded-md border-2 border-gray-200 px-6 py-4">
              <h3 className="mb-3 font-sans text-xl font-bold">
                {state && state.first_name + ","}
              </h3>
              {verifiedViaCC ? (
                <div className="pb-4">
                  <label
                    htmlFor="ssn"
                    className="block text-center font-bold leading-6 text-gray-900"
                  >
                    Because you used a credit card in your name, we don't need further information
                    to verify your identity.
                  </label>
                </div>
              ) : (
                <div className="pb-4">
                  <label htmlFor="ssn" className="block font-bold leading-6 text-gray-900">
                    Please enter the last 4 digits of your social security number so we can verify
                    your identify.
                  </label>
                  <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="text"
                      name="ssn"
                      id="ssn"
                      className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                      placeholder="1234"
                      defaultValue=""
                      aria-invalid="false"
                      aria-describedby="ssn-error"
                      {...register("ssn", { required: true, minLength: 4, maxLength: 4 })}
                    />
                  </div>
                  {errors.ssn && (
                    <p className="mt-2 text-sm text-red-600" id="ssn-error">
                      Four digits are required.
                    </p>
                  )}
                </div>
              )}
              {genericErrorMessage && (
                <div className="mb-2 text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                  {genericErrorMessage.includes("Verification failed") && (
                    <span>
                      {". "}
                      You can try again or{" "}
                      <Link onClick={() => setOpenContactUs(true)}>
                        <span className="text-rasapink underline">contact us</span>
                      </Link>{" "}
                      for help.
                    </span>
                  )}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink p-4 font-bold text-white hover:shadow-xl"
              >
                {verifiedViaCC ? "continue to results" : "verify me"}
              </button>
              {!verifiedViaCC && !verifyFailed && (
                <p>
                  Or, if you'd rather get in contact with a Rasa team member to verify your
                  identity,{" "}
                  <Link onClick={() => setOpenContactUs(true)}>
                    <span className="text-rasapink underline">please contact us here</span>
                  </Link>
                </p>
              )}
            </div>
            <ContactUsModal
              openContactUs={openContactUs}
              setOpenContactUs={setOpenContactUs}
              hasFreshworks={hasFreshworks}
              verifyFailed={verifyFailed}
            />
          </div>
        </form>
      </section>
    </>
  );
};
