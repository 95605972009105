import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { WhyModal } from "@components";
import { SelectLocal } from "../../../../components/SelectLocal";
import { updatePerson } from "../../../../api/secureApi";
import { MANDATORY_SURVEY } from "../../../../constants/surveyTypes";
import { handleError } from "../../../../utils/handleError";
import { AZ, STATE_LIST } from "../../../../constants/usStates";
import { errorUnderInput, questionMain3 } from "../../../../constants/classnames";

const moreThan12 = "more than 12"; // keep lower case
const onlyMe = "only me"; // keep lower case
const dependentOptions = [
  { value: onlyMe, label: onlyMe },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: moreThan12, label: moreThan12 },
];

// https://aspe.hhs.gov/sites/default/files/documents/1c92a9207f3ed5915ca020d58fe77696/detailed-guidelines-2023.pdf
const incomeRangeOnePersonOptions = [
  { value: "I have no income.", label: "I have no income." },
  { value: "less than $18,225", label: "less than $18,225", info: "0-125% federal poverty" },
  { value: "$18,225 - $21,870", label: "$18,225 - $21,870", info: "125%-150% federal poverty" },
  { value: "$21,870 - $29,160", label: "$21,870 - $29,160", info: "150%-200% federal poverty" },
  { value: "$29,160 - $43,740", label: "$29,160 - $43,740", info: "200%-300% federal poverty" },
  { value: "more than $43,740", label: "more than $43,740", info: "over 300% federal poverty" },
];

const incomeRangeTwoPersonOptions = [
  { value: "I have no income.", label: "I have no income." },
  { value: "less than $24,650", label: "less than $24,650", info: "0-125% federal poverty" },
  { value: "$24,650 - $29,580", label: "$24,650 - $29,580", info: "125%-150% federal poverty" },
  { value: "$29,580 - $39,440", label: "$29,580 - $39,440", info: "150%-200% federal poverty" },
  { value: "$39,440 - $59,160", label: "$39,440 - $59,160", info: "200%-300% federal poverty" },
  { value: "more than $59,160", label: "more than $59,160", info: "over 300% federal poverty" },
];

const incomeRangeThreePersonOptions = [
  { value: "I have no income.", label: "I have no income." },
  { value: "less than $31,075", label: "less than $31,075", info: "0-125% federal poverty" },
  { value: "$31,075 - $37,290", label: "$31,075 - $37,290", info: "125%-150% federal poverty" },
  { value: "$37,290 - $49,720", label: "$37,290 - $49,720", info: "150%-200% federal poverty" },
  { value: "$49,720 - $74,580", label: "$49,720 - $74,580", info: "200%-300% federal poverty" },
  { value: "more than $74,580", label: "more than $74,580", info: "over 300% federal poverty" },
];

const incomeRangeFourPersonOptions = [
  { value: "I have no income.", label: "I have no income." },
  { value: "less than $37,500", label: "less than $37,500", info: "0-125% federal poverty" },
  { value: "$37,500 - $45,000", label: "$37,500 - $45,000", info: "125%-150% federal poverty" },
  { value: "$45,000 - $60,000", label: "$45,000 - $60,000", info: "150%-200% federal poverty" },
  { value: "$60,000 - $90,000", label: "$60,000 - $90,000", info: "200%-300% federal poverty" },
  { value: "more than $90,000", label: "more than $90,000", info: "over 300% federal poverty" },
];

const incomeRangeFivePersonOptions = [
  { value: "I have no income.", label: "I have no income." },
  { value: "less than $43,925", label: "less than $43,925", info: "0-125% federal poverty" },
  { value: "$43,925 - $52,710", label: "$43,925 - $52,710", info: "125%-150% federal poverty" },
  { value: "$52,710 - $60,000", label: "$52,710 - $60,000", info: "150%-200% federal poverty" },
  { value: "$60,000 - $105,420", label: "$60,000 - $105,420", info: "200%-300% federal poverty" },
  { value: "more than $60,000", label: "more than $60,000", info: "over 300% federal poverty" },
];

const incomeRangeSixPersonOptions = [
  { value: "I have no income.", label: "I have no income." },
  { value: "less than $50,350", label: "less than $50,350", info: "0-125% federal poverty" },
  { value: "$50,350 - $60,420", label: "$50,350 - $60,420", info: "125%-150% federal poverty" },
  { value: "$60,420 - $80,560", label: "$60,420 - $80,560", info: "150%-200% federal poverty" },
  { value: "$80,560 - $120,840", label: "$80,560 - $120,840", info: "200%-300% federal poverty" },
  { value: "more than $120,840", label: "more than $120,840", info: "over 300% federal poverty" },
];

const incomeRangeSevenPersonOptions = [
  { value: "I have no income.", label: "I have no income." },
  { value: "less than $56,775", label: "less than $56,775", info: "0-125% federal poverty" },
  { value: "$56,775 - $68,130", label: "$56,775 - $68,130", info: "125%-150% federal poverty" },
  { value: "$68,130 - $90,840", label: "$68,130 - $90,840", info: "150%-200% federal poverty" },
  { value: "$90,840 - $136,260", label: "$90,840 - $136,260", info: "200%-300% federal poverty" },
  { value: "more than $136,260", label: "more than $136,260", info: "over 300% federal poverty" },
];

const incomeRangeEightPersonOptions = [
  { value: "I have no income.", label: "I have no income." },
  { value: "less than $63,200", label: "less than $63,200", info: "0-125% federal poverty" },
  { value: "$63,200 - $75,840", label: "$63,200 - $75,840", info: "125%-150% federal poverty" },
  { value: "$75,840 - $101,120", label: "$75,840 - $101,120", info: "150%-200% federal poverty" },
  { value: "$101,120 - $151,680", label: "$101,120 - $151,680", info: "200%-300% federal poverty" },
  { value: "more than $151,680", label: "more than $151,680", info: "over 300% federal poverty" },
];

const incomeRangeNinePersonOptions = [
  { value: "I have no income.", label: "I have no income." },
  { value: "less than $69,625", label: "less than $69,625", info: "0-125% federal poverty" },
  { value: "$69,625 - $83,550", label: "$69,625 - $83,550", info: "125%-150% federal poverty" },
  { value: "$83,550 - $111,400", label: "$83,550 - $111,400", info: "150%-200% federal poverty" },
  { value: "$111,400 - $167,100", label: "$111,400 - $167,100", info: "200%-300% federal poverty" },
  { value: "more than $167,100", label: "more than $167,100", info: "over 300% federal poverty" },
];

const incomeRangeTenPersonOptions = [
  { value: "I have no income.", label: "I have no income." },
  { value: "less than $76,050", label: "less than $76,050", info: "0-125% federal poverty" },
  { value: "$76,050 - $91,260", label: "$76,050 - $91,260", info: "125%-150% federal poverty" },
  { value: "$91,260 - $121,680", label: "$91,260 - $121,680", info: "150%-200% federal poverty" },
  { value: "$121,680 - $182,520", label: "$121,680 - $182,520", info: "200%-300% federal poverty" },
  { value: "more than $182,520", label: "more than $182,520", info: "over 300% federal poverty" },
];

const incomeRangeElevenPersonOptions = [
  { value: "I have no income.", label: "I have no income." },
  { value: "less than $82,475", label: "less than $82,475", info: "0-125% federal poverty" },
  { value: "$82,475 - $98,970", label: "$82,475 - $98,970", info: "125%-150% federal poverty" },
  { value: "$98,970 - $131,960", label: "$98,970 - $131,960", info: "150%-200% federal poverty" },
  { value: "$131,960 - $197,940", label: "$131,960 - $197,940", info: "200%-300% federal poverty" },
  { value: "more than $197,940", label: "more than $197,940", info: "over 300% federal poverty" },
];

const incomeRangeTwelvePersonOptions = [
  { value: "I have no income.", label: "I have no income." },
  { value: "less than $88,900", label: "less than $88,900", info: "0-125% federal poverty" },
  { value: "$88,900 - $106,680", label: "$88,900 - $106,680", info: "125%-150% federal poverty" },
  { value: "$106,680 - $142,240", label: "$106,680 - $142,240", info: "150%-200% federal poverty" },
  { value: "$142,240 - $213,360", label: "$142,240 - $213,360", info: "200%-300% federal poverty" },
  { value: "more than $213,360", label: "more than $213,360", info: "over 300% federal poverty" },
];

const personToRange = {
  1: incomeRangeOnePersonOptions,
  2: incomeRangeTwoPersonOptions,
  3: incomeRangeThreePersonOptions,
  4: incomeRangeFourPersonOptions,
  5: incomeRangeFivePersonOptions,
  6: incomeRangeSixPersonOptions,
  7: incomeRangeSevenPersonOptions,
  8: incomeRangeEightPersonOptions,
  9: incomeRangeNinePersonOptions,
  10: incomeRangeTenPersonOptions,
  11: incomeRangeElevenPersonOptions,
  12: incomeRangeTwelvePersonOptions,
};

export function Income() {
  const navigate = useNavigate();
  const { state } = useLocation();
  // eslint-disable-next-line no-console
  console.log("state in income", state);
  const [openWhy, setOpenWhy] = useState(false);
  const [options, setOptions] = useState(personToRange[1]);
  const [numberOfDependents, setNumberOfDependents] = useState(null);
  const [incomeRange, setIncomeRange] = useState(null);
  // const [incomeForMoreThan12, setIncomeForMoreThan12] = useState("");
  const [numberOfDependentsError, setNumberOfDependentsError] = useState(false);
  const [incomeRangeError, setIncomeRangeError] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const { handleSubmit } = useForm({ mode: "all" });

  // 2375
  const onSubmit = () => {
    if (!numberOfDependents) {
      setNumberOfDependentsError(true);
    } else if (!incomeRange) {
      setIncomeRangeError(true);
    } else {
      // const newState = {
      //   ...state,
      //   number_of_dependents: numberOfDependents.value,
      //   income_range: incomeRange.value ? incomeRange.value : incomeRange,
      // };
      const currentMandatorySurvey = state.surveys.find(
        (survey) => survey.type === MANDATORY_SURVEY,
      );
      // eslint-disable-next-line no-console
      console.log("currentMandatorySurvey", currentMandatorySurvey);
      const stateToSubmit = {
        profile_id: state.profile_id,
        surveys: [
          {
            type: MANDATORY_SURVEY,
            answers: {
              race: currentMandatorySurvey.answers.race,
              race_other: currentMandatorySurvey.answers.race_other || "", // optional field
              ethnicity: currentMandatorySurvey.answers.ethnicity,
              open_cases: currentMandatorySurvey.answers.open_cases,
              on_probation_or_parole: currentMandatorySurvey.answers.on_probation_or_parole,
              other_convictions: currentMandatorySurvey.answers.other_convictions,
              other_convictions_list: currentMandatorySurvey.answers.other_convictions_list || [], // optional field
              expunged_before: currentMandatorySurvey.answers.expunged_before,
              expunged_before_story: currentMandatorySurvey.answers.expunged_before_story || "", // optional field
              number_of_dependents: numberOfDependents.value,
              income_range: incomeRange.value ? incomeRange.value : incomeRange,
            },
          },
        ],
      };
      // eslint-disable-next-line no-console
      console.log("stateToSubmit in income", stateToSubmit);
      updatePerson(stateToSubmit)
        .then((response) => {
          // eslint-disable-next-line no-console
          console.log("response in income", response);
          if (response.us_state === AZ) {
            navigate("/register/arizona-data", { state: response });
          } else {
            navigate("/register/court-data", { state: response });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("error in expunged", error);
          handleError(error, setGenericErrorMessage, navigate);
        });
    }
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className={questionMain3}>
            income
            <InformationCircleIcon
              className="relative -top-1 inline h-6 cursor-pointer text-rasapink"
              onClick={() => setOpenWhy(true)}
            />
          </h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mx-auto my-10 flex max-w-3xl flex-col px-4">
            <div className="pb-4">
              <label
                htmlFor="numberOfDependents"
                className="block font-bold leading-6 text-gray-900"
              >
                including yourself, how many people in your household depend on your financial
                support?
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <SelectLocal
                  id="numberOfDependents"
                  options={dependentOptions}
                  currentValue={numberOfDependents}
                  setCurrentValue={(obj) => {
                    setNumberOfDependents(obj);
                    if (obj) {
                      let val = obj.value;
                      if (val === onlyMe) {
                        val = 1;
                      }
                      if (val === moreThan12) {
                        setShowInput(true);
                      } else {
                        setShowInput(false);
                        setOptions(personToRange[parseInt(val)]);
                      }
                      setIncomeRange("");
                      setIncomeRangeError(false);
                      setNumberOfDependentsError(false);
                    }
                  }}
                  // todo handle more than 12 household
                />
              </div>
              {numberOfDependentsError && (
                <p className={errorUnderInput} id="numberOfDependents-error">
                  Dependents selection is required.
                </p>
              )}
            </div>
            <div className="pb-4">
              <label htmlFor="incomeRange" className="block font-bold leading-6 text-gray-900">
                what is your annual (yearly) income?
              </label>
              {showInput ? (
                <div className="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="text"
                    className="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset
                  ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                    id="incomeRange"
                    value={incomeRange || ""}
                    onChange={(e) => {
                      setIncomeRange(e.target.value);
                      setIncomeRangeError(false);
                      setNumberOfDependentsError(false);
                    }}
                  />
                </div>
              ) : (
                <div className="relative mt-2 rounded-md shadow-sm">
                  <SelectLocal
                    id="incomeRange"
                    options={options}
                    currentValue={incomeRange}
                    setCurrentValue={(v) => {
                      setIncomeRange(v);
                      setIncomeRangeError(false);
                      setNumberOfDependentsError(false);
                    }}
                  />
                </div>
              )}
              {incomeRangeError && (
                <p className={errorUnderInput} id="incomeRange-error">
                  Income range is required.
                </p>
              )}
            </div>
            <WhyModal
              open={openWhy}
              setOpen={setOpenWhy}
              answer={`This data helps us determine whether you might qualify for legal aid under ${
                STATE_LIST[state.us_state]
              } law.`}
            />
            <div className="flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
              >
                next
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
