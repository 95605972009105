import RasaLogo from "@images/rasa-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { flexCenter, logo, menu, menuButton, menuButtonWrapper } from "../../constants/classnames";

export function Menu() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const goToResults = () => {
    navigate("/results", { state: state });
  };

  const goToPersonalStatementInfo = () => {
    navigate("/statement/intro");
  };

  const handleLogoutClick = () => {
    localStorage.setItem("token", "");
    localStorage.setItem("profile_id", "");
    navigate("/login");
  };

  return (
    <>
      <div className="container relative mx-auto pb-6 pt-10">
        <div className={flexCenter}>
          <div className={logo}>
            <RasaLogo className="h-7" />
          </div>
        </div>
      </div>
      <section id="menu" className={menu}>
        <div>
          <div className={menuButtonWrapper}>
            <button type="button" className={menuButton} onClick={goToResults}>
              eligibility results
            </button>
          </div>
          <div className={menuButtonWrapper}>
            <button type="button" className={menuButton} onClick={goToPersonalStatementInfo}>
              personal info
            </button>
          </div>
          <div className={menuButtonWrapper}>
            <button type="button" className={menuButton} onClick={handleLogoutClick}>
              log out
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
